.chat-window {
    display: flex;
    flex-direction: column;
    height: 500px;
    border: 1px solid #ccc;
  }
  
  .chat-header {
    padding: 10px;
    background-color: #f5f5f5;
  }
  
  .message-list {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .message-item {
    margin-bottom: 10px;
  }
  
  .user-message .message-content {
    text-align: right;
    background-color: #dcf8c6;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  .ai-message .message-content {
    text-align: left;
    background-color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  .message-timestamp {
    display: block;
    font-size: 0.8em;
    color: #999;
  }
  
  .message-input {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
  }
  
  .message-input input {
    flex: 1;
    padding: 8px;
  }
  
  .message-input button {
    padding: 8px 16px;
  }