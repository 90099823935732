.personalized-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.chat-container {
  margin-top: 20px;
  height: 600px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
